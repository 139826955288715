import { Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Dev = () => {
    return (
        <>
            <Navbar></Navbar>
            <div className="Home">
                <div className="Message">
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "primary.main" }}>Developer</Typography>
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "secondary.main" }}>Updates</Typography>
                </div>

            </div>
            <Typography component="div" sx={{ display: "flex", fontSize: "1.5rem", margin: "15%", marginTop: "50px" }}>
                <ul>


                    <li>1/26/2025 - Added Google Analytics 4</li>

                    <br></br>

                    <li>11/08/2024 - Changed Tracking Completed Job Functionality</li>
                    <ul>
                        <li>Changed functionailty to allow adding custom variable value to tracking to allow for post job tracking not synced with updated variable</li>
                        <li>Changed functionailty to allow adding of custom dates to all back tracking of previous jobs</li>
                        <li>Added Date and variable checking to restrict users from be able to enter invalid dates or variable amounts</li>
                    </ul>
                    <br></br>

                    <li>11/01/2024 - Website Hosting Change</li>
                    <ul>
                        <li>Changed Hosting Provider to custom Linux Server Via Oracle Cloud Instance</li>
                        <li>Fixed Backend Server Sleep functionality by switching hosting method to allow for instance access to web app</li>
                        <li>Removed 'Product Link' catagory from generated 'Completed PDF' and slightly increased the width of 'Product Name' catagory for 'Completed pdf'</li>
                        <li>Added jest internal testing tool to ensure accuracy of calcMaintenance function </li>
                    </ul>
                    <br></br>

                    <li>12/03/2023 - Dev Page Created</li>
                    <ul>
                        <li>Developer Page Created for tracking website updates</li>
                        <li>Updated Favicon and manifest</li>
                    </ul>
                    <br></br>


                </ul>

            </Typography>

            <Footer></Footer>

        </>
    )
}

export default Dev;