//Removed Browser Router
import { Routes, Route } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext';
import { HashRouter } from 'react-router-dom';
import { useEffect } from 'react';

//pages and components
import Home from './pages/Home'
import Login from './pages/Login'
import CreateAccount from './pages/CreateAccount';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import Task from './pages/Task'
import Error from './pages/Error'
import Dev from './pages/Dev'

import ReactGA from "react-ga4";


function App() {

  const { user } = useAuthContext()
  ReactGA.initialize("G-SLFVFF7HNY");

  useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: window.location.href, title: "DIYtasktracker page" });
  }, [])

  return (
    <div className="App">

      <HashRouter hashType="hashbang">

        <div className='pages'>
          <Routes>
            <Route
              path="/"
              element={<Home />}>
            </Route>
            <Route
              path="/Login"
              element={user ? <Profile /> : <Login />}>
            </Route>
            <Route
              path="/CreateAccount"
              element={user ? <Profile /> : <CreateAccount />}>
            </Route>
            <Route
              path="/ResetPassword"
              element={user ? <Profile /> : <ResetPassword />}>
            </Route>
            <Route
              path="/Profile"
              element={user ? <Profile /> : <Login />}>
            </Route>
            <Route
              path="/Profile/:id"
              element={user ? <Task /> : <Login />}>
            </Route>
            <Route
              path="/Dev"
              element={<Dev />}>
            </Route>
            <Route
              path="*"
              element={<Error />}>
            </Route>
          </Routes>

        </div>

      </HashRouter>
    </div>
  );
}

export default App;
